<template>

  <body class="registration_wraapper">
    <!-- <div class="frames">
            <img src="./../assets/front/image/mobile_frame2.png" class="bottom_frame">
            <img src="./../assets/front/image/mobile_frame4.png">
        </div>-->

    <div class="frame">
      <div class="site-wrapper overflow-hidden">
        <section class="user_login h-100">
          <div class="row no-gutters align-items-center h-100">
            <div class="col-xl-4 offset-xl-7 col-lg-6 offset-lg-6 col-md-12">
              <h1>Reset Password</h1>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
              <validation-observer ref="simpleRules">
                <form id="signin-form" v-on:submit.prevent="validationForm">
                  <div class="form-group">
                    <label>Username or email</label>
                    <validation-provider name="Email" rules="required|email|emailExist|max:50" #default="{ errors }">
                      <input id="email" type="email" name="email" v-model.trim="email"
                        :class="errors.length > 0 ? 'is-invalid' : 'is-valid'" class="form-control" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </div>
                  <p class="text-right"></p>
                  <p class="text-center mt-4">
                    <button class="btn btn-primary">Reset Password</button>
                  </p>
                  <p class="text-center">
                    Not have an account?
                    <router-link to="/registration">Create Account </router-link>
                  </p>
                  <p class="text-center">Already have an account? <router-link to="/signin">Sign in </router-link></p>
                  <br /><br />
                  <p class="text-center">
                    <router-link to="/">Back to Home</router-link>
                  </p>
                </form>
              </validation-observer>
            </div>
          </div>
        </section>
      </div>
    </div>
  </body>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
//Vee-Validate 
import { ValidationProvider, ValidationObserver, validate } from 'vee-validate';
import {
  required, email, emailExist
} from '@validations'

import Vue from "vue";
import axios from "axios";
import firebase from "firebase";
import { db } from "@/main";

export default {
  name: "Login",
  data: function () {
    return {
      email: "",
      password: "",
      passworddate: "",
      otp: "",
      error: "",
      dd: "",
      d: "",
      mm: "",
      yyyy: "",
      name: "",
      passwordupdatedate: "",
      uid: "",
      a: ""
    };
  },
  components: {
    ValidationProvider,
    ValidationObserver,
    required,
    email,
    emailExist
  },

  methods: {
    validationForm() {

      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          // eslint-disable-next-line
          this.submit();
        }
      })
    },
    submit: function () {
      store.dispatch('app/commitActivateLoader');
      db.collection("users")
        .where("email", "==", this.email)
        .get()
        .then((querySnapshot) => {
          //console.log(querySnapshot.size);
          if (querySnapshot.size == 1) {
            querySnapshot.forEach((doc) => {
              console.log(doc.id, " => ", doc.data());
              //console.log(doc.data().size)
              this.passworddate = doc.data().email;
              this.uid = doc.data().user_id;
              this.name = doc.data().firstname;
              this.otp = Math.floor(100000 + Math.random() * 900000);
              this.today = new Date();
              this.a = 1;
              this.d = String(this.today.getDate()).padStart(2, '0');
              this.dd = parseInt(this.d) + parseInt(this.a);
              this.mm = String(this.today.getMonth() + 1).padStart(2, '0'); //January is 0!
              this.yyyy = this.today.getFullYear();
              this.passwordupdatedate = this.mm + '/' + this.dd + '/' + this.yyyy;

              /* update user table */
              firebase.auth().sendPasswordResetEmail(this.email);
              store.dispatch('app/commitDeactivateLoader');
              this.showNotification('Success, Please Check Your Email For Reset Password !', 'UserIcon', 'success');
              /*End update user table */

            });
          } else {
            store.dispatch('app/commitDeactivateLoader');
            this.showNotification('Error, Email not Exist!', 'UserIcon', 'danger');
          }
        })
        .catch((error) => {
          console.log("Error getting documents: ", error);

        });


    },

    showNotification(title, icon, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          variant,
        },
      })
    },

  },
};
</script>


<style scoped>
.user_login {
  height: 86vh;
}

.user_login h1 {
  font-size: 30px;
  margin-bottom: 20px;
}

.user_login form {
  margin-top: 50px;
}

.user_login .form-control {
  background: #f2f4f5 !important;
  height: 40px;
  border-color: #f2f4f5;
}

.user_login .form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #dadcdc;
  outline: 0;
}

.user_login label {
  display: block;
}

.user_login .row.h-100 {
  position: relative;
}

.user_login .row.h-100:before {
  background: url(../../assets/front/image/sign_in.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  position: absolute;
  content: "";
  width: 50%;
  height: 100%;
}

.frames {
  position: absolute;
  height: 100vh;
}

.frames .bottom_frame {
  position: absolute;
  bottom: 0;
}

.user_login .row.h-100 .col-xl-4 {
  background: #fff;
  padding: 25px;
  border-radius: 5px;
  overflow-y: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.user_login .row.h-100 .col-xl-4::-webkit-scrollbar,
.frame:before,
.frame:after {
  display: none;
}

.site-wrapper {
  height: 100vh;
  border: none;
  border-radius: 0;
}

body {
  background: #fff;
}

.user_login {
  margin-top: 0;
}



.registration_wraapper::after,
.registration_wraapper::before {
  position: absolute;
  content: "";
  z-index: 9;
  transform: translateX(-50%);
}

.registration_wraapper::before {
  width: 120px;
  height: 10px;
  background: #fff;
  top: 32px;
  left: 50%;
  border-radius: 20px;
  opacity: .5;
}

.registration_wraapper::after {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  top: 29px;
  left: calc(50% - 90px);
  background: #898b8d;
}

.site-wrapper {
  margin: 0 auto;
  position: relative;
  border: 25px solid #14171b;
  border-top-width: 25px;
  border-bottom-width: 25px;
  border-top-width: 25px;
  border-bottom-width: 25px;
  border-radius: 119px;
  border-top-width: 72px;
  z-index: 8;
  border-bottom-width: 72px;
}


@media only screen and (max-width: 992px) {

  .frames,
  .user_login .row.h-100:before {
    display: none;
  }

  .user_login {
    height: 100vh;
    margin-top: 0;
  }

  .user_login>.row {
    background: none;
    padding: 0px 15px;
  }
}

@media only screen and (max-width: 600px) {
  .user_login .row.h-100 .col-xl-4 {
    justify-content: flex-start;
  }

  .site-wrapper.overflow-hidden {
    border: none;
  }

  .registration_wraapper[data-v-72666184]::after,
  .registration_wraapper[data-v-72666184]::before {
    display: none;
  }
}
</style>
